import { format } from "date-fns";
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function formatDate(timestamp: number, f = "yyyy-MM-dd HH:mm:ss") {
  const date = new Date(timestamp);

  return format(date, f);
}

export function formatRuntime(start: number, end: number): string {
  if (start > end) {
    return "-";
  }
  const distance = end - start;
  const hours = Math.floor(distance / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return `${hours < 10 ? `0${hours}` : `${hours}`}:${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
}

export function pluralize(
  count: number,
  singular: string,
  plural: string
): string {
  return `${count} ${count === 0 || count === 1 ? singular : plural}`;
}

export function bytesToGB(bytes: number) {
  return bytes / Math.pow(1024, 3);
}

export function GBToBytes(gb: number) {
  return gb * Math.pow(1024, 3);
}

export function random(m: number, n: number) {
  const factor = n - m + 1;
  return Math.floor(Math.random() * factor + m);
}

export function addAlpha(color: string, opacity: number) {
  const res = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + res.toString(16).toUpperCase();
}

export function computeAverage(a: number | null, b: number | null): string {
  if (typeof a === "number" && typeof b === "number") {
    const mean = ((a + b) / 2) * 100;
    return `${parseFloat(mean.toFixed(1))}%`;
  } else if (typeof a === "number") {
    const mean = a * 100;
    return `${parseFloat(mean.toFixed(1))}%`;
  } else if (typeof b === "number") {
    const mean = b * 100;
    return `${parseFloat(mean.toFixed(1))}%`;
  } else {
    return "-";
  }
}

export function computePercentage(a?: number | null, digit = 2): string {
  if (typeof a === "number") {
    return `${parseFloat((a * 100).toFixed(digit))}%`;
  }
  return "";
}

export function expo(x: number, f = 2) {
  const xAbs = Math.abs(x);
  if (x === 0) {
    return x;
  } else if (xAbs < 0.05) {
    return x.toExponential(f);
  }

  return parseFloat(x.toFixed(f));
}

export function getDownloadableCsv(list: string[][]): string {
  const titles = list[0];
  let idx = titles.indexOf("file_ids");

  if (idx === -1) {
    idx = titles.indexOf("file_id_dict");
  }

  const filteredList = list
    .filter((l) => {
      return l.length === titles.length;
    })
    .map((l) => {
      return l.filter((item, i) => {
        return i !== idx;
      });
    });

  const text = filteredList
    .map((l) => {
      const arr = l.map((item) => {
        if (typeof item === "number") {
          return item;
        } else {
          return `"${item}"`;
        }
      });
      return arr.join(",");
    })
    .join("\n");

  return text;
}

export function syntaxHighlight(json: string | Record<string, unknown>) {
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

export function toPercentile(x?: number, digit = 2) {
  if (typeof x === "undefined") {
    return "-";
  } else if (x === 0) {
    return "0%";
  } else {
    const y = parseFloat((x * 100).toFixed(digit));

    return `${y}%`;
  }
}

export function reserveBits(n: number, d = 3) {
  if (typeof n !== "number") {
    return "-";
  }

  if (n === 0) {
    return n;
  }

  return parseFloat(n.toFixed(d));
}
