import http, { request } from "@config/http";
import { Page, Pagination, List } from "@services/common/model/page.model";
import {
  UserModel,
  QueryParamsModel,
  GithubUserModel,
  AddUserModel,
  ConfigUserModel,
  PricingModel,
  ReviewModel,
} from "@services/user-manage/model/user.model";
import {
  DashboardCardModel,
  DashboardChartParamsModel,
  ChartValueModel,
} from "@services/organization/model/organization.model";

export const getuserListApi = async (params: QueryParamsModel) => {
  const { data } = await http.get<null, Page<Pagination<UserModel>>>(
    "user/all",
    {
      params,
    }
  );
  return data;
};

export const addUserApi = async (params: AddUserModel) => {
  return http.post<null, Page<null>>("user/all", {
    ...params,
  });
};

export const deleteUserApi = async (uids: string[]) => {
  return http.post<null, Page<null>>("user/delete", {
    uids,
  });
};

export const configUserApi = async (params: ConfigUserModel) => {
  return http.put<null, Page<null>>("user/all", {
    ...params,
  });
};

export const getLoginUserInfo = async () => {
  return request.get<null, Page<UserModel>>("user/info");
};

export const getGithubUserInfo = async (accessToken: string) => {
  return request<null, GithubUserModel>({
    method: "get",
    url: `https://api.github.com/user`,
    headers: {
      accept: "application/json",
      Authorization: `token ${accessToken}`,
    },
  });
};

export const cancelRedDotApi = async () => {
  return http.post<null, Page<null>>("user/cancel_red_dot", {});
};

export const payPricingApi = async (params: PricingModel) => {
  return http.post<null, Page<null>>("user/pay/alipay", {
    ...params,
  });
};

export const validateInviteCodeApi = async (invite_code: string) => {
  return request.post<null, Page<null>>(
    `user/check_invite_code/${invite_code}`,
    {}
  );
};

export const changeUserStateApi = async (params: ReviewModel) => {
  return http.put<null, Page<null>>(`user/change_state`, { ...params });
};

export const getUserDashboardCardListApi = async (
  org_plan_id?: string,
  user_id?: string
) => {
  const { data } = await http.get<null, Page<List<DashboardCardModel>>>(
    "org_plan/user_plan_cost_total",
    {
      params: {
        org_plan_id,
        user_id,
      },
    }
  );
  return data.list;
};

export const getUserDashboardChartApi = async (
  params: DashboardChartParamsModel
) => {
  const { data } = await http.get<null, Page<List<ChartValueModel>>>(
    "org_plan/user_plan_cost_per_day",
    {
      params,
    }
  );
  return data.list;
};
